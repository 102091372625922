import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { jobAction, jobSelector } from "../../redux/reducers/job-reducer";
import { initialState } from "../../redux/reducers/job-reducer";

type UseJobProps = {
    createOrUpdateJob:(payload:any) => any 
    getJob:(payload:any) => any 
    deactivateJob:(payload:any) => any 
    deleteJob:(payload:any) => any

} & typeof initialState

export const useJob = ():UseJobProps => {
    const dispatch = useDispatch();
    const state = useSelector(jobSelector);


    const createOrUpdateJob = (payload:any) => dispatch<any>(jobAction({path:"/job", payload}));
    const getJob = (payload:any) => dispatch<any>(jobAction({path:"/jobs", payload}));
    const deactivateJob = (payload:any) => dispatch<any>(jobAction({path:"/job/status", payload}));
    const deleteJob = (payload:any) => dispatch<any>(jobAction({path:"/job/status", payload,}));

    return {
        ...state,
        createOrUpdateJob, 
        getJob, 
        deactivateJob, 
        deleteJob
    }
}