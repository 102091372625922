
export function addToCart(items:any[], item:any) {
    return [...items, item];
}

export function removeFromCart(items:any[], item:any) {
    const newItems = items.filter((itemInCart)=> itemInCart.id !== item.id);
    return newItems;
}

export function increaseQuantityCart(items:any[], item:any, qty:number) {

    items.map((value)=>{
        if (item.id === value.id) {
            let quantity = value.quantity += qty;
            return {...value, quantity }
        }
    });
    return items;
}

export function decreaseQuantityCart(items:any[], item:any, qty:number) {

    items.map((value)=>{
        if (item.id === value.id && value.quantity > 1) {
            let quantity = value.quantity -= qty;
            return {...value, quantity }
        }
    });
    return items;
}

export function getItemFromCart() {
    return;
}
