import React, { useState } from "react";
import cn from "classnames";

type CardProps = {
    item?:any
    cardStyli?:string
    children?:any
    onClick?:any
}
export function PackageCard({ item, cardStyli,onClick }:CardProps) {
    const [check, setCheck] = useState<boolean>(false);
    let currentItem = Object.entries(item);
    
    return (<div className={cn("flex flex-col", cardStyli)} onClick={()=>{
                setCheck(Boolean(item));
                
                setTimeout(() => {
                    onClick();
                }, 1000);
            }}>
            <input 
                onChange={(e)=>console.log(e)}
                type="radio"
                name="name"
                checked={check}
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded m-5"
            />
            {currentItem.map((value:any, index)=>(
            <div className="bg-white p-3" key={index}>
                {<div className="flex justify-between items-center">
                    {value[0] !== "type" && value[0] !== "full_details" && 
                    <div className="capitalize">{(value[0]as string).replace("_"," ")}</div>}
                    {value[0] !== "full_details" && 
                    <div className={cn(
                        value[0] === "type"  
                            ? "text-center text-xl capitalize mx-auto font-bold"
                            :"capitalize"
                        )}>{value[1]}</div>}
                </div>}
            </div>))}
    </div>)
}
export function Card({ item, cardStyli, }:CardProps) {
    let currentItem = Object.entries(item);
    return (<div className={cn("flex flex-col", cardStyli)}>
        {currentItem.map((value:any, index)=>(
        <div className="bg-white p-3" key={index}>
            {(value[0] as string).charAt(0) !== "_" && 
            <div className="flex justify-between items-center">
                <div>{value[0]}</div>
                <div>{value[1]}</div>
            </div>}
        </div>
        ))}
    </div>)
}
export function CardWrapper({ item, cardStyli, children }:CardProps) {
    return (<div className={cn("bg-white",cardStyli)}>{children}</div>)
}