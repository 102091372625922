import './spinner.css';
import cn from 'classnames';

interface Props {
  classNameFrame?: string;
  classNameSpin?: string;
  mini?: boolean;
}

const Loader = ({ classNameFrame, classNameSpin, mini = false }: Props) => {
  return (<div className={cn(
    mini 
    ? ''
    : 'my-[25%]',
    classNameFrame)}>
    <div id="simple_loading" className={cn(
      mini 
      ? 'w-6 h-6'
      : 'w-20 h-20',
      "m-auto bg-gray-200 border-t-[10px] border-blue-900 rounded-[50%]", 
      classNameSpin)}/>
  </div>);
};

export default Loader;
