import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { subcriptionPlanAction, subcriptionPlanSelector } from "../../redux/reducers/subcriptionPlan-reducer";
import { initialState } from "../../redux/reducers/subcriptionPlan-reducer";

type UseSubcriptionPlanProps = {
    createOrUpdateSubcriptionPlan:(payload:any) => any 
    getSubcriptionPlan:(payload:any) => any 
    deactivateSubcriptionPlan:(payload:any) => any 
    deleteSubcriptionPlan:(payload:any) => any

} & typeof initialState

export const useSubcriptionPlan = ():UseSubcriptionPlanProps => {
    const dispatch = useDispatch();
    const state = useSelector(subcriptionPlanSelector);


    const createOrUpdateSubcriptionPlan = (payload:any) => dispatch<any>(subcriptionPlanAction({path:"/subcriptionPlan", payload}));
    const getSubcriptionPlan = (payload:any) => dispatch<any>(subcriptionPlanAction({path:"/subcriptionPlans", payload}));
    const deactivateSubcriptionPlan = (payload:any) => dispatch<any>(subcriptionPlanAction({path:"/subcriptionPlan/status", payload}));
    const deleteSubcriptionPlan = (payload:any) => dispatch<any>(subcriptionPlanAction({path:"/subcriptionPlan/status", payload,}));

    return {
        ...state,
        createOrUpdateSubcriptionPlan, 
        getSubcriptionPlan, 
        deactivateSubcriptionPlan, 
        deleteSubcriptionPlan
    }
}