import React from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import {
  deletionsAction,
  deletionsSelector,
  initialState,
} from "../../redux/reducers/deletions-reducer";

type UseDeletionsProps = {
  createOrUpdateDeletions: (payload?: any) => any;
  getDeletions: (payload?: any) => any;
  getUserDeletionRequest: (payload?: any) => any;
} & typeof initialState;

export const useDeletions = (): UseDeletionsProps => {
  const dispatch = useDispatch();
  const store = useStore();
  const state = useSelector(deletionsSelector);

  const createOrUpdateDeletions = (payload: any) =>
    dispatch<any>(deletionsAction({ path: "/deletion", payload }));
  const getDeletions = (payload: any) =>
    dispatch<any>(deletionsAction({ path: "/deletions", payload }));
  const getUserDeletionRequest = (payload: any) =>
    dispatch<any>(deletionsAction({ path: "/deletion/retrieve", payload }));

  return {
    ...state,
    createOrUpdateDeletions,
    getDeletions,
    getUserDeletionRequest,
  };
};
