import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { categoryAction, categorySelector } from "../../redux/reducers/category-reducer";
import { initialState } from "../../redux/reducers/category-reducer";

type UseCategoryProps = {
    createOrUpdateCategory:(payload:any) => any 
    getCategory:(payload:any) => any 
    deactivateCategory:(payload:any) => any 
    deleteCategory:(payload:any) => any

} & typeof initialState

export const useCategory = ():UseCategoryProps => {
    const dispatch = useDispatch();
    const state = useSelector(categorySelector);


    const createOrUpdateCategory = (payload:any) => dispatch<any>(categoryAction({path:"/category", payload}));
    const getCategory = (payload:any) => dispatch<any>(categoryAction({path:"/categories", payload}));
    const deactivateCategory = (payload:any) => dispatch<any>(categoryAction({path:"/category/status", payload}));
    const deleteCategory = (payload:any) => dispatch<any>(categoryAction({path:"/category/status", payload,}));

    return {
        ...state,
        createOrUpdateCategory, 
        getCategory, 
        deactivateCategory, 
        deleteCategory
    }
}