import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { orderAction } from "../../redux/reducers/order-reducer";
import { initialState, orderSelector } from "../../redux/reducers/order-reducer";

type UseOrderProps = {
    createOrUpdateOrder:(payload:any) => any 
    getOrder:(payload:any) => any 
    deactivateOrder:(payload:any) => any 
    deleteOrder:(payload:any) => any

} & typeof initialState

export const useOrder = ():UseOrderProps => {
    const dispatch = useDispatch();
    const state = useSelector(orderSelector);


    const createOrUpdateOrder = (payload:any) => dispatch<any>(orderAction({path:"/order", payload}));
    const getOrder = (payload:any) => dispatch<any>(orderAction({path:"/orders", payload}));
    const deactivateOrder = (payload:any) => dispatch<any>(orderAction({path:"/order/status", payload}));

    return {
        ...state,
        createOrUpdateOrder, 
        getOrder, 
        deactivateOrder
    }
}