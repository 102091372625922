
/**
 * Currency formatter take number up to quadrillion
 * @param digit string value of number or string
 * @param result internal use 
 * @param newNum internal use 
 * 
 * package: lib in-view
 * Author: Sylvester Ifeanyi Ekweozor
 * email: sylvester.ekweozor@gmail.com
 * MIT lisence Adopt
 */
// @ts-ignore
let decimal = ".00";

export const formatter = (digit:number | string, result:string = "", newNum:boolean = true):any => {

    let stringify = String(digit),
    index = stringify.indexOf("."),
    numToString = newNum === true && index !== -1 ? stringify.slice(0,index) : stringify,
    lengthOfNum = numToString.length,
    thousand = 3,
    million = 6,
    billion = 9,
    trillion = 12,
    quadrillion = 15;

    decimal = newNum === true && index !== -1 ? stringify.slice(index,index+3) : decimal;
    
  
    if(lengthOfNum > quadrillion) {//quadrillion
   
        let initialIndex = lengthOfNum - quadrillion;
  
        let outPut = _formatter(result, initialIndex, numToString);
  
        return formatter(outPut.remainingNum, outPut.result, false);
    
    } else if (lengthOfNum > trillion) {//trillion
        let initialIndex = lengthOfNum - trillion;
  
        let outPut = _formatter(result, initialIndex, numToString);
  
        return formatter(outPut.remainingNum, outPut.result, false);
    
    } else if (lengthOfNum > billion) {//billion
        let initialIndex = lengthOfNum - billion;
  
        let outPut = _formatter(result, initialIndex, numToString);
  
        return formatter(outPut.remainingNum, outPut.result, false);
    
    } else if (lengthOfNum > million) {//million
        let initialIndex = lengthOfNum - million;
  
        let outPut = _formatter(result, initialIndex, numToString);
  
        return formatter(outPut.remainingNum, outPut.result, false);
    
    } else if (lengthOfNum > thousand) {//thousand
        let initialIndex = lengthOfNum - thousand;
  
        let picker = numToString.slice(0,initialIndex);
  
        let remainingNum = numToString.slice(initialIndex);
  
        result += initialIndex === 1 
        ? "00"+picker+","+remainingNum
        : initialIndex === 2 
        ? "0"+picker+","+remainingNum
        : picker+","+remainingNum;
        
        return formatter(remainingNum, result, false);
    
    } else if (newNum) {//if the original number is less a thousand digits
        return numToString+decimal;
    }
    
    return _removePrefixZeros(result,decimal);
  
  }
  
/**
 * separating by commas
 * @param result string 
 * @param initialIndex number 
 * @param numToString string
 */   
function _formatter(result: string, initialIndex:number, numToString:string) {
  
    let picker = numToString.slice(0,initialIndex);
    
    result += initialIndex === 1 ? "00"+picker+",": initialIndex === 2 ? "0"+picker+",": picker+",";
    
    let remainingNum = numToString.slice(initialIndex);
    
    return {remainingNum: remainingNum, result: result}
}

/**
 * removing prgramme prefix zeros
 * @param result string 
 */
  function _removePrefixZeros(result:string,decimal:any) {    
  
    let lastOfFirst = result.indexOf(","),
  
    restOfNums = result.slice(lastOfFirst),
  
    firstDigits = result.slice(0,lastOfFirst),
  
    newFirstDigits = firstDigits[0] === "0" && firstDigits[1] === "0"  ? firstDigits.slice(2) 
        :firstDigits[0] === "0" ? firstDigits.slice(1) 
        : firstDigits,
  
    newResult = newFirstDigits+restOfNums;
    
    return newResult+decimal;
  }
  

// console.log(formatter(3085.0617809970176));//637363737474012
//run node num-formatter-alogarithm-by-sylvester-i-e.ts