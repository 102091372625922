import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { subcriptionAction, subcriptionSelector } from "../../redux/reducers/subcription-reducer";
import { initialState } from "../../redux/reducers/subcription-reducer";

type UseSubcriptionProps = {
    createOrUpdateSubcription:(payload:any) => any 
    getSubcription:(payload:any) => any 
    deactivateSubcription:(payload:any) => any 
    deleteSubcription:(payload:any) => any

} & typeof initialState

export const useSubcription = ():UseSubcriptionProps => {
    const dispatch = useDispatch();
    const state = useSelector(subcriptionSelector);


    const createOrUpdateSubcription = (payload:any) => dispatch<any>(subcriptionAction({path:"/subcription", payload}));
    const getSubcription = (payload:any) => dispatch<any>(subcriptionAction({path:"/subcriptions", payload}));
    const deactivateSubcription = (payload:any) => dispatch<any>(subcriptionAction({path:"/subcription/status", payload}));
    const deleteSubcription = (payload:any) => dispatch<any>(subcriptionAction({path:"/subcription/status", payload,}));

    return {
        ...state,
        createOrUpdateSubcription, 
        getSubcription, 
        deactivateSubcription, 
        deleteSubcription
    }
}