import React from "react";

type TagShortDecriptionProps = {
  title?:string
  description?:string
  outerlayerStyle:string
  innerlayerStyle:string
  innerHeaderStyle:string
  innerBodyStyle:string
}
export function TagShortDecription({ 
  title, 
  description, 
  outerlayerStyle, 
  innerBodyStyle, 
  innerHeaderStyle, 
  innerlayerStyle 
}:TagShortDecriptionProps) {
    return (
    <div className={outerlayerStyle}>
      <div className={innerBodyStyle}>
        <h3 className={innerHeaderStyle}>{title}</h3>
        <p className={innerlayerStyle}>{description}</p>
      </div>
    </div>
    )
}