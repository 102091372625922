import React from "react";

import { usePersonal } from "./user-detail";


export const usePermission = (aboveLimit:number):number => {
  const { data } = usePersonal(true); 

    // @ts-ignore
    let abilities = data?.abilities ?? "[1]";
    let authorization = JSON.parse(abilities);
    const isAdmin = authorization.find((v:number) => (v > aboveLimit));

    return isAdmin
}