import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { supportAction, supportSelector } from "../../redux/reducers/support-reducer";
import { initialState } from "../../redux/reducers/support-reducer";

type UseSupportProps = {
    createOrUpdateSupport:(payload:any) => any 
    getSupport:(payload:any) => any 
    deactivateSupport:(payload:any) => any 
    deleteSupport:(payload:any) => any

} & typeof initialState

export const useSupport = ():UseSupportProps => {
    const dispatch = useDispatch();
    const state = useSelector(supportSelector);


    const createOrUpdateSupport = (payload:any) => dispatch<any>(supportAction({path:"/support", payload}));
    const getSupport = (payload:any) => dispatch<any>(supportAction({path:"/supports", payload}));
    const deactivateSupport = (payload:any) => dispatch<any>(supportAction({path:"/support/status", payload}));
    const deleteSupport = (payload:any) => dispatch<any>(supportAction({path:"/support/status", payload,}));

    return {
        ...state,
        createOrUpdateSupport, 
        getSupport, 
        deactivateSupport, 
        deleteSupport
    }
}