import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { initialState, shippingAction, shippingSelector } from "../../redux/reducers/shipping-reducer";

type UseShippingProps = {
    createOrUpdateShipping:(payload:any) => any 
    getShipping:(payload:any) => any 
    deactivateShipping:(payload:any) => any 
    deleteShipping:(payload:any) => any

} & typeof initialState

export const useShipping = ():UseShippingProps => {
    const dispatch = useDispatch();
    const state = useSelector(shippingSelector);


    const createOrUpdateShipping = (payload:any) => dispatch<any>(shippingAction({path:"/shipping", payload}));
    const getShipping = (payload:any) => dispatch<any>(shippingAction({path:"/shippings", payload}));
    const deactivateShipping = (payload:any) => dispatch<any>(shippingAction({path:"/shipping/status", payload}));
    const deleteShipping = (payload:any) => dispatch<any>(shippingAction({path:"/shipping/status", payload,}));

    return {
        ...state,
        createOrUpdateShipping, 
        getShipping, 
        deactivateShipping, 
        deleteShipping
    }
}