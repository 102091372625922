import { ActionProps, DataProps } from "../../types";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "axios";
import { baseURl } from "../env/host-url";

export const initialState:DataProps = {
    info: {
        status:false,
        query:'',
        detail:null,
        reset_token:null,
        HLL:null
    },
    loading: false,
    error: ''
};


export const jobAction = createAsyncThunk("job/jobAction/", async ({ path, payload, token }: ActionProps ) => {
    try {        
        const headers:any = {
            'Content-Type':'application/json',
            'Accept':'application/json',
            // "Authorization": `Bearer ${token}`
        }; 
        const response = await axios.post(`${baseURl+path}`,payload, {headers});
        return response.data;
    } catch (error) {
        // @ts-ignore
        return error.message
    }
});

export const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {},
    extraReducers(builder){
        builder.addCase(jobAction.pending,(state, action) => {
            state.loading = true;
        })
        .addCase(jobAction.fulfilled,(state, action) => {
            state.loading = false;
            state.info = action.payload;
            state.error = "";
        })
        .addCase(jobAction.rejected,(state, action) => {
            state.loading = false;
            state.error = action.error.message
        })
    }
})


export default jobSlice.reducer

export const jobSelector = (state:any) => state.job;
