import React from "react";
import { useDispatch, useSelector, useStore } from "react-redux";

import {
  chatsAction,
  chatsSelector,
  initialState,
} from "../../redux/reducers/chat-reducer";

type UseChatProps = {
  createOrUpdateChat: (payload?: any) => any;
  getChat: (payload?: any) => any;
  chatStatus: (payload?: any) => any;
  getUserChat: (payload?: any) => any;
} & typeof initialState;

export const useChat = (): UseChatProps => {
  const dispatch = useDispatch();
  const store = useStore(),
    state = useSelector(chatsSelector);

  const createOrUpdateChat = (payload: any) =>
    dispatch<any>(chatsAction({ path: "/chat", payload }));
  const getChat = (payload: any) =>
    dispatch<any>(chatsAction({ path: "/chats", payload }));
  const getUserChat = (payload: any) =>
    dispatch<any>(chatsAction({ path: "/chat/me", payload }));
  const chatStatus = (payload: any) =>
    dispatch<any>(chatsAction({ path: "/chat/status", payload }));

  return {
    ...state,
    createOrUpdateChat,
    getChat,
    chatStatus,
    getUserChat,
  };
};
