import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { initialState, productAction, productSelector } from "../../redux/reducers/product-reducer";

type UseProductProps = {
    createOrUpdateProduct:(payload:any) => any 
    getProduct:(payload:any) => any 
    deactivateProduct:(payload:any) => any 
    deleteProduct:(payload:any) => any

} & typeof initialState

export const useProduct = ():UseProductProps => {
    const dispatch = useDispatch();
    const state = useSelector(productSelector);


    const createOrUpdateProduct = (payload:any) => dispatch<any>(productAction({path:"/product", payload}));
    const getProduct = async (payload:any) => dispatch<any>(productAction({path:"/products", payload}));
    const deactivateProduct = (payload:any) => dispatch<any>(productAction({path:"/product/status", payload}));
    const deleteProduct = (payload:any) => dispatch<any>(productAction({path:"/product/status", payload}));

    return {
        ...state,
        createOrUpdateProduct, 
        getProduct, 
        deactivateProduct, 
        deleteProduct
    }
}