import { createSlice } from "@reduxjs/toolkit";
import { addToCart, decreaseQuantityCart, increaseQuantityCart, removeFromCart, } from "../actions/cart-action";

export type Action = {
    [key:string]:any
}
export const initialState:any = {
    items:[],
    total_items:0,
    total_price:0,
    total_price_fxws:0,
    total_wieght:0,
    isEmpty:true,
}
export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        ADD_TO_CART: (state:typeof initialState = initialState, action:Action) => {
            const { item } = CartParser(action);
            let newItems = addToCart(state.items,item);
            return UpdateCart(state, newItems);
        },
        REMOVE_FROM_CART: (state:typeof initialState = initialState, action:Action) => {
            const { item } = CartParser(action);
            let updatedItems = removeFromCart(state.items,item);
            return UpdateCart(state, updatedItems);
        }, 
        INCREASE_QUANTITY: (state:typeof initialState = initialState, action:Action) => {
            const { item, update } = CartParser(action);
            let updatedItemsByQuantity1 = increaseQuantityCart(state.items, item, update);
            return UpdateCart(state, updatedItemsByQuantity1);
        }, 
        DECREASE_QUANTITY: (state:typeof initialState = initialState, action:Action) => {
            const { item, update } = CartParser(action);
            let updatedItemsByQuantity2 = decreaseQuantityCart(state.items,item, update);
            return UpdateCart(state, updatedItemsByQuantity2);
        }, 
        RETRIEVE_ITEMS_FROM_CART: (state:typeof initialState = initialState) => {
            return UpdateCart(state, state.items);
        }, 
        CLEAR_CART: () => {
            window.localStorage.removeItem("cart");
            return {
                items:[],
                total_items:0,
                total_price:0,
                total_wieght:0,
                isEmpty:true,
            };
        }, 
        RETRIEVE_ITEMS_FROM_BACKUP_CART: (state:typeof initialState = initialState, action:Action) => {
            state.items = action.payload.items;
            state.total_items = action.payload.total_items;
            state.total_price = action.payload.total_price;
            state.total_price_fxws = action.payload.total_price_fxws;
            state.total_wieght = action.payload.total_wieght;
            state.isEmpty = action.payload.isEmpty;
        }, 
          
    }
})
const CartParser = (action:Action) =>{
    let length = Object.entries(action).length > 2;
    let item = Object.values(action)[1];
    let update = length ? Object.values(action)[2] : null;
    return {
        length,
        item,
        update
    }
}

function UpdateCart(state:typeof initialState,update:any[]) {

    let total_price_fxws = update.reduce((previous,current)=>{
        return previous + (current.sale_price_fxws * current.quantity);
    },0);

    let total_price = update.reduce((previous,current)=>{
        return previous + (current.sale_price * current.quantity);
    },0)

    let total_wieght = update.reduce((previous,current)=>{
        return previous + current.weight;
    },0)
    
    let total_items = update.reduce((previous,current)=>{
        return previous + current.quantity;
    },0)
    // console.log(update)

    let cart = {
        items:update,
        total_items,
        total_price,
        total_wieght,
        total_price_fxws,
        isEmpty:update.length > 0 ? false : true,
    }
    window.localStorage.setItem("cart", JSON.stringify(cart));//backup copy
    return cart;
}

export const { 
    ADD_TO_CART, 
    CLEAR_CART, 
    DECREASE_QUANTITY, 
    INCREASE_QUANTITY, 
    REMOVE_FROM_CART,
    RETRIEVE_ITEMS_FROM_CART,
    RETRIEVE_ITEMS_FROM_BACKUP_CART, 
} = cartSlice.actions;

export default cartSlice.reducer

export const cartSelector = (state:any) => state.cart;