import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { brandAction, brandSelector } from "../../redux/reducers/brand-reducer";
import { initialState } from "../../redux/reducers/brand-reducer";

type UseBrandProps = {
    createOrUpdateBrand:(payload:any) => any 
    getBrand:(payload:any) => any 
    deactivateBrand:(payload:any) => any 
    deleteBrand:(payload:any) => any

} & typeof initialState

export const useBrand = ():UseBrandProps => {
    const dispatch = useDispatch();
    const state = useSelector(brandSelector);


    const createOrUpdateBrand = (payload:any) => dispatch<any>(brandAction({path:"/brand", payload}));
    const getBrand = (payload:any) => dispatch<any>(brandAction({path:"/brands", payload}));
    const deactivateBrand = (payload:any) => dispatch<any>(brandAction({path:"/brand/status", payload}));
    const deleteBrand = (payload:any) => dispatch<any>(brandAction({path:"/brand/status", payload,}));

    return {
        ...state,
        createOrUpdateBrand, 
        getBrand, 
        deactivateBrand, 
        deleteBrand
    }
}