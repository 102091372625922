import { ActionProps, DataProps } from "../../types";
import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  Dispatch,
} from "@reduxjs/toolkit";
import axios from "axios";
import { baseURl } from "../env/host-url";

export const initialState: DataProps = {
  info: {
    status: false,
    query: "",
    detail: null,
    reset_token: null,
    HLL: null,
  },
  loading: false,
  error: "",
};

export const deletionsAction = createAsyncThunk(
  "deletions/deletionsAction",
  async ({ path, payload, token }: ActionProps) => {
    try {
      const headers: any = {
        "Content-Type": "application/json",
        Accept: "application/json",
        // "Authorization": `Bearer ${token}`
      };
      const response = await axios.post(`${baseURl + path}`, payload, {
        headers,
      });
      return response.data;
    } catch (error) {
      // @ts-ignore
      return error.message;
    }
  }
);

export const deletionsSlice = createSlice({
  name: "deletions",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(deletionsAction.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(deletionsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.info = action.payload;
        state.error = "";
      })
      .addCase(deletionsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default deletionsSlice.reducer;

export const deletionsSelector = (state: any) => state.deletions;
